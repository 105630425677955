'use client'

import React, { useRef, useEffect, useState } from 'react'
import { useTranslations } from 'next-intl'

import AutocompleteInput from './input/AutocompleteInput'
import AutocompletePopup from './popup/AutocompletePopup'
import AutocompletePopupItem from './popup/AutocompletePopupItem'
import {
  isPopupSectionVisible,
  getNextFocusIndex,
  getPreviousFocusIndex,
  willOpenLinkInNewTab,
} from './helpers/autocomplete.utils'
import { useKeyboardNav } from './hooks/useKeyboardNav'
import { useClickOutside } from './hooks/useClickOutside'
import { useSearch } from './hooks/useSearch'
import { useHeaderContext } from '../header-context'
import type { Item, AutocompleteState } from './types'
import { getIsCpEnabled } from '@/common/utils'
import { useStoreContext } from '@/providers'

type AutocompleteProps = {
  isDesktop?: boolean
  popularSearch: Item[]
}

export function Autocomplete({ isDesktop, popularSearch }: AutocompleteProps) {
  const t = useTranslations('Header')
  const { storeCode } = useStoreContext()
  const isCpEnabled = getIsCpEnabled(storeCode)
  const [state, setState] = useState<AutocompleteState>({
    state: 'initial',
    input: '',
    isFocused: false,
    focusedIndex: 0,
    focusedItem: undefined,
    focusedList: [],
    popupSections: [
      {
        title: t('search.menu.popular'),
        list: popularSearch,
        externalLink: !isCpEnabled,
        skeletonTarget: 'category',
      },
    ],
  })

  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const prevInputRef = useRef('')
  const cachedQueriesRef = useRef(new Set())

  const {
    state: { isMobileSearch },
  } = useHeaderContext()

  const { isLoading, products, categories, brands, trackClick } = useSearch({
    text: state.input,
    enabled:
      state.isFocused && !!state.input && state.input !== prevInputRef.current,
  })

  const reset = (currentState?: Partial<AutocompleteState>) => {
    prevInputRef.current = state.input
    setState((prevState) => ({
      ...prevState,
      state: 'initial',
      focusedItem: undefined,
      focusedIndex: 0,
      ...currentState,
    }))
  }

  const handleFocus = (isFocused: boolean) => {
    if (isFocused === state.isFocused) {
      return
    }

    reset({ isFocused })
  }

  const handleChange = (input: string) => {
    if (state.input === input) {
      return
    }

    if (!input) {
      reset({ input: '' })
      return
    }

    prevInputRef.current = state.input
    setState((prevState) => ({
      ...prevState,
      focusedItem: undefined,
      focusedIndex: 0,
      state: cachedQueriesRef.current.has(input) ? 'initial' : 'fetching',
      input,
    }))
  }

  const handleClose = (query?: string) => {
    reset({ input: query ?? '', isFocused: false, state: 'submitting' })
  }

  const handleSubmit = (e?: unknown, focusedPopupItem?: Item): void => {
    if (!state.input || state.state === 'submitting') {
      return
    }

    if (focusedPopupItem) {
      trackClick(focusedPopupItem)
    }

    /**
     * To prevent being stuck in "submitting" state
     * when opening a link in a new tab/window
     * -> don't enter it in the first place...
     *
     * Note:
     * there is still a way to open in new tab
     * like through context menu or mouse middle click
     * but those events wont get triggered as it's only captured onMouseDown
     * and here only onClick events get
     * so the tracking won't capture that kind of use-case
     */
    if (willOpenLinkInNewTab(e)) {
      return
    }

    prevInputRef.current = state.input
    setState((prevState) => ({
      ...prevState,
      isFocused: false,
      focusedIndex: 0,
      focusedItem: focusedPopupItem ?? undefined,
      input: focusedPopupItem ? focusedPopupItem.label : '',
      state: 'submitting',
    }))
  }

  useClickOutside({
    ref: wrapperRef,
    enabled: state.isFocused,
    onClickOutside: () => {
      handleFocus(false)
    },
  })

  useKeyboardNav({
    enabled: state.isFocused,
    onArrowDown: () => {
      const i = getNextFocusIndex(state)

      setState((prevState) => ({
        ...prevState,
        focusedIndex: i,
        focusedItem: state.focusedList[i],
      }))
    },
    onArrowUp: () => {
      const i = getPreviousFocusIndex(state)

      setState((prevState) => ({
        ...prevState,
        focusedIndex: i,
        focusedItem: state.focusedList[i],
      }))
    },
    onEnter: (e) => {
      handleSubmit(e, state.focusedItem)
    },
    onEsc: () => {
      handleFocus(false)
    },
  })

  useEffect(() => {
    if (!isLoading) {
      cachedQueriesRef.current.add(state.input)
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    const isPopularVisible = isPopupSectionVisible('popular', state.input)
    const isProductsVisible = isPopupSectionVisible('products', state.input)
    const isCategoriesVisible = isPopupSectionVisible('categories', state.input)
    const isBrandsVisible = isPopupSectionVisible('brands', state.input)

    setState((prevState) => ({
      ...prevState,
      focusedList: [
        ...(isPopularVisible ? popularSearch : []),
        ...(isProductsVisible ? products : []),
        ...(isCategoriesVisible ? categories : []),
        ...(isBrandsVisible ? brands : []),
      ],
      popupSections: [
        {
          title: t('search.menu.popular'),
          list: isPopularVisible ? popularSearch : [],
          externalLink: !isCpEnabled,
          skeletonTarget: 'category',
        },
        {
          title: t('search.menu.product'),
          list: isProductsVisible ? products : [],
          externalLink: false,
          skeletonTarget: 'product',
        },
        {
          title: t('search.menu.categories'),
          list: isCategoriesVisible ? categories : [],
          externalLink: !isCpEnabled,
          skeletonTarget: 'category',
        },
        {
          title: t('search.menu.brands'),
          list: isBrandsVisible ? brands : [],
          externalLink: !isCpEnabled,
          skeletonTarget: 'category',
        },
      ],
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popularSearch, products, categories, brands, state.input])

  return (
    <div
      ref={wrapperRef}
      className="lg:mx-[85px] relative hidden w-full py-1 transition-all aria-hidden:!hidden data-[ui=mobile]:flex data-[ui=desktop]:max-w-[295px] data-[ui=desktop]:md:flex data-[ui=desktop]:lg:max-w-[464px]"
      aria-hidden={!isDesktop && !isMobileSearch}
      data-ui={isDesktop ? 'desktop' : 'mobile'}
    >
      <AutocompleteInput
        focusedPopupItem={state.focusedItem}
        isFocused={state.isFocused}
        state={state.state}
        input={state.input}
        isDesktop={isDesktop}
        onFocus={handleFocus}
        onChange={handleChange}
        onSubmit={handleSubmit}
        handleClose={handleClose}
      />
      <AutocompletePopup
        isOpen={
          state.isFocused &&
          state.state !== 'submitting' &&
          state.focusedList?.length > 0
        }
      >
        {state.popupSections.map(
          (section) =>
            section.list.length > 0 && (
              <React.Fragment key={section.title}>
                <div className="text-xs font-bold uppercase py-1">
                  {section.title}
                </div>
                <ul role="listbox">
                  {section.list.map((item: Item) => (
                    <AutocompletePopupItem
                      key={item.id}
                      query={state.input}
                      isFocused={item.id === state.focusedItem?.id}
                      onSubmit={handleSubmit}
                      isExternal={section.externalLink}
                      target={section.skeletonTarget}
                      handleClose={handleClose}
                      {...item}
                    />
                  ))}
                </ul>
              </React.Fragment>
            ),
        )}
      </AutocompletePopup>
    </div>
  )
}
