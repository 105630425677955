import { SEARCH_POPUP_ID } from '../constants'
import type { PropsWithChildren } from 'react'

type Props = {
  isOpen: boolean
}

function AutocompletePopup({ children, isOpen }: PropsWithChildren<Props>) {
  return (
    <dialog
      id={SEARCH_POPUP_ID}
      role="presentation"
      className="absolute left-0 top-[30px] z-[999] hidden min-h-[100px] w-full border-2 border-black bg-white px-4 py-2 outline-none open:block max-h-[calc(100vh-200px)] overflow-y-auto md:max-h-[calc(100vh-120px)]"
      open={isOpen}
    >
      {children}
    </dialog>
  )
}

export default AutocompletePopup
