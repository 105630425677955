import { useEffect, useRef } from 'react'

import { Link } from '@/components/link/link'
import { AutocompletePopupItemHighlighted } from './AutocompletePopupItemHighlighted'
import type { Item } from '../types'
import { SkeletonType } from '@/components/skeleton/PageSkeleton'

type Props = {
  id: string
  label: string
  url: string
  img?: string
  query: string
  isFocused: boolean
  isExternal: boolean
  target: SkeletonType
  onSubmit: (e: unknown, focusedPopupItem: Item) => void
  handleClose: (query?: string) => void
}

function AutocompletePopupItem({
  id,
  label,
  url,
  img,
  query,
  isFocused,
  isExternal,
  target,
  onSubmit,
  handleClose,
}: Props) {
  const linkRef = useRef<HTMLAnchorElement>(null)

  const getData = (target: SkeletonType) => {
    if (target === 'product') {
      return {
        data: { productName: label },
      }
    }

    return {}
  }

  useEffect(() => {
    if (isFocused) {
      linkRef.current?.focus()
    }
  }, [isFocused])

  return (
    <li
      className="px-2 py-1 text-xs hover:underline aria-selected:underline"
      role="option"
      aria-selected={isFocused}
      id={id}
    >
      <Link
        ref={linkRef}
        className="flex w-full items-center justify-start gap-2"
        href={url}
        isExternal={isExternal}
        skeletonLoader={{
          target,
          ...getData(target),
        }}
        onClick={(e) => {
          onSubmit(e, {
            id,
            label,
            url,
          })
          handleClose('label')
        }}
      >
        {img && (
          <div
            className="w-[68px] h-[68px] bg-contain bg-center bg-no-repeat"
            style={{
              backgroundImage: `url(${img})`,
            }}
          />
        )}
        {query && (
          <AutocompletePopupItemHighlighted label={label} query={query} />
        )}
        {!query && label}
      </Link>
    </li>
  )
}

export default AutocompletePopupItem
