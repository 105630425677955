'use client'

import React, { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { WebsiteTypeFragment } from '@/api'
import { CountryFlag } from '@/components/icons/flags'
import { selectors } from '@/common/constants/selectors-constants'
import { Link } from '@/components/link/link'
import { Domain } from '@/common/types'
import { useBrowserMediaQuery } from '@/common/hooks/use-browser-media-query'
import ChevronUpIcon from '@/components/icons/svg/chevron-up'

export type FooterStoreMenuProps = {
  isServerMobile: boolean
  websites: WebsiteTypeFragment[]
  actualWebsite?: WebsiteTypeFragment
}

export function FooterStoreMenu({
  websites,
  actualWebsite,
  isServerMobile,
}: FooterStoreMenuProps): JSX.Element {
  const isMd = useBrowserMediaQuery({ breakpoint: 'md' })

  const [isOpen, setIsOpen] = useState(false)

  const isMobile = isMd ?? isServerMobile
  /**
   * for CSR = true, for SSR and disabled JavaScript = FALSE
   */
  const isJavaScriptEnabled = typeof window !== 'undefined'
  const isMenuVisible = isJavaScriptEnabled && isMobile && actualWebsite

  if (isMenuVisible) {
    return (
      <div className="bg-primary text-white p-6">
        <div
          className="flex h-10 items-center border"
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        >
          <CountryFlag
            icon={actualWebsite.icon as Domain}
            className="w-7 h-5 shrink-0 mx-3"
          />

          <span className="w-full">{actualWebsite?.nameMobile} </span>

          <ChevronUpIcon
            className={twMerge(
              'w-6 h-6 shrink-0 mr-2 stroke-[2.7]',
              isOpen ? 'rotate-0' : 'rotate-180',
            )}
          />
        </div>

        {isOpen && (
          <div className="border border-t-0">
            {websites.map((website) => (
              <Link
                keepUrl
                href={website.url}
                className="flex h-10 items-center text-white"
                key={website.icon}
                aria-label={website.nameMobile}
              >
                <CountryFlag
                  icon={website.icon as Domain}
                  className="w-7 h-5 mx-3 shrink-0"
                />

                <span className="w-full">{website.nameMobile} </span>
              </Link>
            ))}
          </div>
        )}
      </div>
    )
  }

  return (
    <div
      data-test={selectors.common.footer.websiteSwitcher}
      className="bg-white text-white text-center p-2"
    >
      {websites?.map((website, index) => {
        const isLast = index === websites.length - 1

        return (
          <React.Fragment key={website.url}>
            <Link
              keepUrl
              href={website.url}
              className="inline-block text-primary"
              aria-label={website.nameDesktop}
            >
              <span>{website.nameDesktop}</span>
            </Link>

            {!isLast && <span className="text-primary px-2">|</span>}
          </React.Fragment>
        )
      })}
    </div>
  )
}
