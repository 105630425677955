/* eslint-disable @next/next/no-img-element */
import { useState, useRef, useEffect } from 'react'
import { useTranslations } from 'next-intl'

import { Image } from '@/common/components/image/image'
import { selectors } from '@/common/constants/selectors-constants'
import { SearchIcon } from '@/components/icons/svg'
import { DELAY_INPUT_CHANGE_MS, SEARCH_POPUP_ID } from '../constants'
import { getQueryUrl } from '../helpers/autocomplete.utils'
import type { Item, SearchState } from '../types'
import { Link } from '@/components/link/link'
// TODO: use CDN instead of filesystem
import LoaderGif from '@/assets/loader.gif'
import { getIsExternal, getSkeletonTarget } from '@/common/utils'
import { useStoreContext } from '@/providers'

type TimeoutIdType = NodeJS.Timeout | string | number | undefined

type Props = {
  isFocused: boolean
  input: string
  state: SearchState
  focusedPopupItem?: Item
  isDesktop?: boolean
  onFocus: (isFocused: boolean) => void
  onChange: (input: string) => void
  onSubmit: (e?: unknown) => void
  handleClose: (query?: string) => void
}

function AutocompleteInput({
  isFocused,
  input,
  state,
  focusedPopupItem,
  isDesktop,
  onFocus,
  onChange,
  onSubmit,
  handleClose,
}: Props) {
  const updateTimeoutIdRef = useRef<TimeoutIdType>(undefined)
  const submitRef = useRef<HTMLAnchorElement>(null)
  const [value, setValue] = useState('')
  const t = useTranslations('Header')
  const { storeCode } = useStoreContext()
  const isDisabled = !value || state === 'submitting'
  const isExternal = getIsExternal(storeCode, value)
  const target = getSkeletonTarget(storeCode, value)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ?? ''

    setValue(value)

    if (focusedPopupItem) {
      onChange(value)
    }

    updateTimeoutIdRef.current && clearTimeout(updateTimeoutIdRef.current)
    updateTimeoutIdRef.current = setTimeout(() => {
      onChange(value)
    }, DELAY_INPUT_CHANGE_MS)
  }

  const handleEnterSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isDisabled && e.code === 'Enter') {
      onSubmit()
      handleClose(value)
      submitRef.current?.click()
    }
  }

  const handleClickSubmit = () => {
    if (value) {
      onSubmit()
      handleClose(value)
      submitRef.current?.click()
    }
  }

  useEffect(() => {
    if (!input) {
      setValue('')
    }
  }, [input])

  useEffect(() => {
    return () => {
      clearTimeout(updateTimeoutIdRef.current)
    }
  }, [])

  return (
    <>
      <input
        className="h-7 w-full border-2 border-black px-2 text-sm outline-none disabled:border-secondary"
        onChange={handleChange}
        onKeyDown={handleEnterSubmit}
        onFocus={() => onFocus(true)}
        value={value}
        disabled={state === 'submitting'}
        placeholder={
          isFocused ? t('search.placeholderFocused') : t('search.placeholder')
        }
        role="combobox"
        aria-expanded={isFocused}
        aria-label="Search"
        aria-controls={SEARCH_POPUP_ID}
        aria-haspopup="listbox"
        aria-activedescendant={focusedPopupItem?.id ?? ''}
        autoComplete="off"
        data-test={selectors.common.autocomplete.input}
      />
      <button
        onClick={handleClickSubmit}
        disabled={state === 'submitting'}
        className="z-20 flex w-7 h-7 items-center justify-center bg-black text-white outline-none ring-secondary focus-visible:ring-2 disabled:bg-secondary aria-disabled:cursor-default aria-hidden:hidden"
        aria-disabled={isDisabled}
        aria-hidden={!isDesktop}
      >
        <SearchIcon
          aria-label="Search"
          width={16}
          height={16}
          className="w-5 h-5"
        />
      </button>
      <Image
        className="absolute right-14 top-[10px] w-4 h-4 aria-hidden:hidden data-[ui=mobile]:right-7"
        data-ui={!isDesktop ? 'mobile' : ''}
        src={LoaderGif}
        alt="Loading"
        aria-hidden={state !== 'fetching'}
        unoptimized
      />
      <Link
        className="hidden"
        ref={submitRef}
        href={value ? getQueryUrl(value) : ''}
        key={value}
        isExternal={isExternal}
        skeletonLoader={{
          target,
        }}
      />
    </>
  )
}

export default AutocompleteInput
