import { useEffect, useMemo, useRef } from 'react'

import { useXsearchQuery } from '@/api'
import { Item } from '../types'
import {
  trackClick,
  trackResults,
  trackSearch,
} from '../helpers/autocomplete.utils'
import { MAX_INPUT_CHARS } from '../constants'

type UseSearch = {
  isLoading: boolean
  products: Item[]
  categories: Item[]
  brands: Item[]
  trackClick: (Item) => void
}

type Props = {
  text: string
  enabled: boolean
}

export function useSearch({ text, enabled }: Props): UseSearch {
  const { data, isLoading } = useXsearchQuery(
    { text: text.slice(0, MAX_INPUT_CHARS) },
    { enabled },
  )
  const { products, categories, brands } = data?.xsearch ?? {}
  const prevSearch = useRef(text)
  const prevResults = useRef(text)
  const searchTime = useRef(Date.now())
  const resultsTime = useRef(Date.now())

  useEffect(() => {
    if (text && prevSearch.current !== text) {
      prevSearch.current = text
      searchTime.current = Date.now()
      trackSearch({
        searchTime: searchTime.current,
        text,
      })
    }
  }, [text])

  return useMemo(() => {
    const productsList =
      products?.map((item) => ({
        id: `${item?.id}`,
        label: `${item?.name}`,
        url: `${item?.url}`,
        img: `${item?.image}`,
      })) ?? []

    const categoriesList =
      categories?.map((item) => ({
        id: `${item?.id}`,
        label: `${item?.name}`,
        url: `${item?.url}`,
        img: `${item?.image}`,
      })) ?? []

    const brandsList =
      brands?.map((item) => ({
        id: `${item?.id}`,
        label: `${item?.name}`,
        url: `${item?.url}`,
        img: `${item?.image}`,
      })) ?? []

    if (!isLoading && text?.length >= 3 && prevResults.current !== text) {
      prevResults.current = text
      resultsTime.current = Date.now()
      trackResults({
        productsList,
        searchTime: searchTime.current,
        resultsTime: resultsTime.current,
        text,
      })
    }

    return {
      isLoading,
      products: productsList,
      categories: categoriesList,
      brands: brandsList,
      trackClick: trackClick({
        productsList,
        searchTime: searchTime.current,
        resultsTime: resultsTime.current,
        text,
      }),
    }
  }, [products, categories, brands, isLoading, text])
}
